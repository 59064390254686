<template>
  <div>
    <img v-if="NewsBanner != null" :src="NewsBanner" class="main-banner-img" />
    <section class="blog-area section-gap" id="blog">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text">
            <h1>ข่าวและกิจกรรม</h1>
            <!-- <p>News and Activity</p> -->
          </div>
        </div>

        <div class="row" v-if="NewsGallery != []">
          <gallery :images="NewsGallery" :index="IndexGallery" @close="IndexGallery = null"
          :options="OptionGallery"
          ></gallery>
          <div class="image" v-for="(image, Index) in NewsGallery" :key="Index" @click="IndexGallery = Index"></div>
        </div>

        <!-- <div class="row" v-if="VideoGallery != []">
          <video-gallery :images="VideoGallery" :index="IndexVideo" 
          :options="{youTubeVideoIdProperty: 'youtube', youTubePlayerVars: undefined, youTubeClickToPlay: true}"
          @close="index = null"></video-gallery>
          <div
            class="image"
            v-for="(image, Index) in VideoGallery" :key="Index"
            @click="IndexVideo = Index"
          ></div>
        </div> -->
        
        <div class="row">
          <div class="single-blog col-lg-4 col-md-4" v-for="(event, index) in GalleryList" :key="index">
            <div class="thumb">
              <!-- <img class="img-fluid" v-if="event.Pic !== null" :src="require(`@/assets/img/events/${event.Pic}`)"/> -->
              <img class="img-fluid" v-if="event.Pic !== null" :src="event.Pic" v-on:click="getEventGalleryList(event)"/>
              <img class="img-fluid" v-else src="./../../assets/img/image_not_available.png" />
            </div>
            <div class="bottom d-flex justify-content-between align-items-center flex-wrap">
              <div class="meta">{{event.Name}}</div>
            </div>
            <a href="#">
              <h4>{{event.Topic}}</h4>
            </a>
            <p>{{event.Desc}}</p>
          </div>
          <!-- <gallery :images="images" :index="index" @close="index = null"></gallery> -->
          <!-- <div
            class="image"
            v-for="(image, imageIndex) in images" :key="imageIndex"
            @click="index = imageIndex"
            :style="{ backgroundImage: 'url(' + image + ')', width: '300px', height: '200px' }"
          ></div> -->
        </div>

        <div class="row">
          <div class="single-blog col-lg-4 col-md-4" v-for="(event, index) in VideoList" :key="index">
            <div class="thumb">
              <!-- <img class="img-fluid" v-if="event.Pic !== null" :src="event.Pic" v-on:click="getEventGalleryList(event)"/> -->
              <img class="img-fluid" v-if="event.Pic !== null" :src="event.Pic" v-on:click="showVideoModal(event.Name, event.VideoUrl)"/>
              <img class="img-fluid" v-else src="./../../assets/img/image_not_available.png" />
            </div>
            <div class="bottom d-flex justify-content-between align-items-center flex-wrap">
              <div class="meta">{{event.Name}}</div>
            </div>
            <a href="#">
              <h4>{{event.Topic}}</h4>
            </a>
            <p>{{event.Desc}}</p>
          </div>
        </div>
      </div>
    </section>
    <video-modal ref="myVideoModal"></video-modal>
  </div>
</template>

<script>
import axios from 'axios';
import conf from  "../../conf";
import VueGallery from 'vue-gallery';
import VideoModel from "../../layout/VideoModal.vue";

export default {
  name: "News",
  components: {
    "gallery": VueGallery,
    "video-modal": VideoModel
    // 'video-gallery': VueGallery
  },
  data() {
    return {
      EventList: [],
      GalleryList: [],
      VideoList: [],
      StaticData: [],
      NewsBanner: null,
      NewsGallery: [],
      IndexGallery: null,
      VideoGallery: [],
      IndexVideo: null,
      OptionGallery: null
    };
  },
  methods: {
    getEventList() {
      axios.get(conf.ApiURL+`/Site/GetNewsList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.EventList = res.data;
                this.GalleryList = [];
                this.VideoList = [];
                for(var i = 0; i < this.EventList.length; i++){
                  var Type = this.EventList[i].Type;
                  // console.log(Type);
                  if(Type == "P")
                    this.GalleryList.push(this.EventList[i]);
                  else
                    this.VideoList.push(this.EventList[i]);
                }

              });
    },
    getEventGalleryList(_Event) {
      if(_Event.Type == "P")
      {
        axios.get(conf.ApiURL+`/Site/GetNewsGallery?Nid=`+_Event.Nid, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                // console.log(res.data.Msg);
                this.NewsGallery = [];
                let Data = JSON.parse(res.data.Msg);
                for(var i = 0; i < Data.length; i++){
                  this.NewsGallery.push(Data[i].Pic);
                }

                if(this.NewsGallery.length > 0)
                  this.IndexGallery = 0;

                // console.log(this.NewsGallery);
                // this.NewsGallery = JSON.parse(res.data.Msg);
              });
      }
      else{
        this.NewsGallery = [];
        this.OptionGallery = null;
        if(_Event.VideoUrl != '' && _Event.VideoUrl != null){
        let _Video = {
          href: _Event.VideoUrl,
          type: 'text/html',
          youtube: _Event.VideoUrl.replace('https://www.youtube.com/watch?v=',''),
        }
        this.NewsGallery.push(_Video);

        if(this.NewsGallery.length > 0)
            this.IndexGallery = 0;
        }
      }
    },
    getVideoGalleryList(VideoUrl){
      // console.log(VideoUrl);
      this.IndexVideo = -1;
      this.VideoGallery = [];
      this.OptionGallery = {youTubeVideoIdProperty: 'youtube', youTubePlayerVars: undefined, youTubeClickToPlay: true};
      if(VideoUrl != '' && VideoUrl != null){
        let _Video = {
          href: VideoUrl,
          type: 'text/html',
          youtube: VideoUrl.replace('https://www.youtube.com/watch?v=',''),
        }
        this.VideoGallery.push(_Video);

        if(this.VideoGallery.length > 0)
          this.IndexVideo = 0;
        // console.log(this.VideoGallery);
      }
    },
    getSiteData(){
      setTimeout(() => {
        this.StaticData = this.$parent.getStaticData();
        if(this.StaticData.length == 1)
        {
          this.NewsBanner = this.StaticData[0].NewsBanner;
        }
        else{
          this.getSiteData();
        }
      }, 500);
    },
    doEsc()
    {
      // console.log("Test");
    },
    showVideoModal(Name, _VideoUrl){
      // console.log(_VideoUrl);
      _VideoUrl = "https://www.youtube.com/embed/"+ _VideoUrl.replace("https://www.youtube.com/watch?v=","");
      this.$refs.myVideoModal.showModal(Name, _VideoUrl);
    }
  },
  mounted() {
    this.getSiteData();
    this.getEventList();
  }
};
</script>

<style>
.hide-gallery{
  display: none;
}
</style>