<template>
  <div>
    <!-- Start contact-page Area -->
	<img v-if="ContactBanner != null" :src="ContactBanner" class="main-banner-img" />
	<section class="contact-page-area section-gap">
		<div class="container">
			<div class="row">
				<div class="map-wrap" style="width:100%; height: 445px;" id="map">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3877.9288185716914!2d100.34032271493224!3d13.601159590445011!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e2b9178d228de3%3A0x9c9b746067ed25dc!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4muC4teC4reC4suC4o-C5jOC5gOC4reC5h-C4oSDguK3guLTguJnguYDguJXguK3guKPguYzguYDguJnguIrguLHguYjguJnguYHguJnguKUg4LiI4Liz4LiB4Lix4LiU!5e0!3m2!1sth!2sth!4v1561994004385!5m2!1sth!2sth" width="100%" height="445" frameborder="0" style="border:0" allowfullscreen></iframe>
				</div>
				<div class="col-lg-4 d-flex flex-column address-wrap">
					<div class="single-contact-address d-flex flex-row">
						<div class="icon">
							<span class="lnr lnr-home"></span>
						</div>
						<div class="contact-details">
							<h5>บริษัท บีอาร์เอ็ม อินเตอร์เนชั่นแนล จำกัด</h5>
							<p v-if="SiteData !== null">{{SiteData.Address}}</p>
						</div>
					</div>
					<div class="single-contact-address d-flex flex-row">
						<div class="icon">
							<span class="lnr lnr-phone-handset"></span>
						</div>
						<div class="contact-details">
							<h5 v-if="SiteData !== null">{{SiteData.Phone}}</h5>
							<!-- <p>จ-ศ 08:00-17:00</p> -->
						</div>
					</div>
					<div class="single-contact-address d-flex flex-row">
						<div class="icon">
							<span class="lnr lnr-printer"></span>
						</div>
						<div class="contact-details">
							<h5 v-if="SiteData !== null">{{SiteData.Fax}}</h5>
							<!-- <p>จ-ศ 08:00-17:00</p> -->
						</div>
					</div>
					<div class="single-contact-address d-flex flex-row">
						<div class="icon">
							<span class="lnr lnr-envelope"></span>
						</div>
						<div class="contact-details">
							<h5 v-if="SiteData !== null">{{SiteData.Email}}</h5>
						</div>
					</div>	
					<div class="single-contact-address d-flex flex-row">
						<div class="icon">
							<span class="lnr lnr-map-marker"></span>
						</div>
						<div class="contact-details">
							<h5 v-if="SiteData !== null && SiteData.MapPic !== null"><a :href="SiteData.MapPic" target="_blank">BRM Map (Download)</a></h5>
						</div>
					</div>													
				</div>
				<div class="col-lg-8">
					<!-- <form class="form-area " id="myForm" action="mail.php" method="post" class="contact-form text-right"> -->
					<div class="contact-form text-right">
						<div class="row">	
							<div class="col-lg-6 form-group">
								<input v-model="MessageItem.Name" name="name" placeholder="ชื่อ-สกุล" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your name'" class="common-input mb-20 form-control" required="" type="text">
							
								<input v-model="MessageItem.Email" name="email" placeholder="Email address" pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter email address'" class="common-input mb-20 form-control" required="" type="email">

								<input v-model="MessageTopic" name="subject" placeholder="หัวข้อ" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your subject'" class="common-input mb-20 form-control" required="" type="text">
								<div class="mt-20 alert-msg" style="text-align: left;"></div>
							</div>
							<div class="col-lg-6 form-group">
								<textarea v-model="MessageItem.Message" class="common-textarea form-control" name="message" placeholder="ข้อความ" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Messege'" required=""></textarea>
								<button @click="CreateNewMessage" class="genric-btn primary circle mt-30" style="float: right;">ฝากข้อความ</button>								
							</div>
						</div>
					</div>
					<!-- </form>	 -->
				</div>
			</div>
		</div>	
	</section>
	<!-- End contact-page Area -->
  </div>
</template>

<script>
import axios from 'axios';
import conf from  "../conf";

export default {
  name: "About",
  components: {},
  data() {
    return {
	  AboutText: "",
	  StaticData:[],
	  SiteData: null,
	  ContactBanner: null,
	  MessageTopic: null,
	  MessageItem: {
		Qid: null,
		Name: null,
		Tel: null,
		Email: null,
		Message: null,
		CreateDate: null
	  }
    };
  },
  methods: {
    getSiteData(){
		setTimeout(() => {
			this.StaticData = this.$parent.getStaticData();
			if(this.StaticData.length == 1)
			{
				this.SiteData = this.StaticData[0];
				this.ContactBanner = this.SiteData.ContactBanner;
				// console.log(this.SiteData);
			}
			else{
				this.getSiteData();
			}
	  	}, 500);
	},
	CreateNewMessage(){
      let formData;
		formData = { 
			Qid: null,
			Name: this.MessageItem.Name,
			Tel: this.MessageItem.Tel,
			Email: this.MessageItem.Email,
			Message: "หัวข้อ:" + this.MessageTopic + " รายละเอียด:" +this.MessageItem.Message,
			CreateDate: null
		};

		const encodeForm = (data) => {
			return Object.keys(data)
				.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
				.join('&');
		};

		axios.post(conf.ApiURL+`/Setting/CreateNewMessage`, 
				encodeForm(formData)
				)
				.then(res => {
					// var status = res.data.Status;
					var msg = res.data.Msg;
					this.$parent.showMsg("Bmroller.com", msg);

					this.MessageTopic = null;
					this.MessageItem = {
											Qid: null,
											Name: null,
											Tel: null,
											Email: null,
											Message: null,
											CreateDate: null
										};
				});
    }
  },
  mounted() {
    this.getSiteData();
  }
};
</script>
