<template>
  <div>
    <!-- start banner Area -->
    <app-banner></app-banner>
    <!-- <section class="banner-area relative" id="home">
      <div class="overlay overlay-bg"></div>
      <div class="container">
        <div class="row fullscreen d-flex align-items-center justify-content-center">
          <div class="banner-content col-lg-12 col-md-12">
            <h4 class="text-white">ผู้เชี่ยวชาญด้านลูกกลิ้งยาง</h4>
            <h1>BRM International Co., Ltd.</h1>
            <h4 class="text-white">บริษัท บีอาร์เอ็ม อินเตอร์เนชั่นแนล จำกัด</h4>
            <br />
            <a href="#" class="primary-btn header-btn text-uppercase">ติดต่อเรา</a>
          </div>
        </div>
      </div>
    </section> -->
    <!-- End banner Area -->

    <!-- Start service Area -->
    <section class="service-area section-gap" id="service">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text text-center">
            <h1 class="mb-10">บริการของเรา</h1>
            <!-- <p>Our Service</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="(service, index) in SerciveList" :key="index">
            <div class="single-service">
              <a href="/services">
                <div class="thumb">
                  <img v-if="service.Pic !== null" :src="service.Pic"/>
                  <img v-else src="./../../assets/img/image_not_available.png" />
                </div>
                <h4>{{service.Name}}</h4>
              </a>
              <!-- <p class="desc" v-if="service.Description !== null && service.Description !== 'null'">{{service.Description}}</p> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End service Area -->

    <!-- Start home-about Area -->
    <section class="home-about-area section-gap" id="about">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 col-md-12 home-about-left">
            <div class="content-html-home" v-html="AboutText">
            </div>
          </div>
          <div class="col-lg-4 col-md-12 home-about-right relative">
            <!-- <form class="form-wrap" action="#"> -->
            <div class="form-wrap">
              <h4 class="text-white pb-20">ขอใบเสนอราคา</h4>
              <div class="form-select" id="service-select">
                <select v-model="QuotationSvKey" @change="serviceChange">
                  <option value="0">กรุณาเลือก บริการ</option>
                  <option
                    v-for="(service, index) in SerciveList"
                    :key="index"
                    v-bind:value="index"
                  >{{service.Name}}</option>
                </select>
              </div>
              <input type="text" v-model="QuotationItem.Name" class="form-control" placeholder="ชื่อ" />
              <input type="phone" v-model="QuotationItem.Tel" class="form-control" placeholder="โทรศัพท์" />
              <input type="email" v-model="QuotationItem.Email" class="form-control" placeholder="Email Address" />
              <textarea
                v-model="QuotationItem.Message"
                name="message"
                id
                cols="30"
                rows="5"
                placeholder="ข้อความ"
                class="form-control"
              ></textarea>
              <button class="primary-btn" v-on:click="CreateNewQuataion">ขอใบเสนอราคา</button>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </section>
    <!-- End home-about Area -->

    <!-- Start feedback Area -->
    <section class="video-area relative" id="feedback">
      <div class="overlay overlay-bg-main"></div>
      <div class="container">
        <div class="row feedback-contents justify-content-center align-items-center">
          <div class="col-lg-6">
              <div class="videoWrapper">
                <iframe width="560" height="315" :src="VideoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
          </div>
          <div class="col-lg-6 feedback-right">
            <p class="text-white pb-20 video-desc">{{VideoDesc}}</p>
          </div>
        </div>
      </div>
    </section>
		<!-- End feedback Area -->

    <!-- Start Product Area -->
    <section class="service-area section-gap" id="product">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text text-center">
            <h1 class="mb-10">สินค้าของเรา</h1>
            <!-- <p>Our Product</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="(prd, index) in ProductList" :key="index">
            <router-link :to="{ name: 'product', params: { name: prd.Name }}">
              <div class="single-service">
                <div class="thumb">
                  <!-- <img v-if="prd.Pic !== null" :src="require(`@/assets/img/products/${prd.Pic}`)" /> -->
                  <img v-if="prd.Pic !== null" :src="prd.Pic" />
                  <img v-else src="./../../assets/img/image_not_available.png" />
                </div>
                <h4>{{prd.Name}}</h4>
                <p v-if="prd.Desc !== null">{{prd.Desc}}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!-- End Product Area -->

    <!-- Start feedback Area -->
    <section class="feedback-area section-gap relative" id="feedback">
      <div class="overlay overlay-bg-main"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text text-center">
            <h1 class="mb-10 text-white">ลูกค้าที่ให้ความไว้วางใจ</h1>
            <!-- <p class="text-white">Our Partner</p> -->
          </div>
        </div>
        <div class="row feedback-contents justify-content-center align-items-center">
          <div class="col-md-12">
            <div class="partner-logo">
              <!-- <slick ref="slick" :options="slickPartnerOptions"> -->
                  <!-- <div class="" v-for="(partner, index) in PartnerList" :key="index"> -->
              <div class="slide">
                <a :href="partner.Link" target="_blank" class="partner-logo" v-for="(partner, index) in PartnerList" :key="index">
                  <img class="" v-if="partner.Pic !== null" :src="partner.Pic">
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- End feedback Area -->

    <!-- Start blog Area -->
    <section class="blog-area section-gap" id="blog">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text">
            <h1>ข่าวและกิจกรรม</h1>
            <!-- <p>News and Activity</p> -->
          </div>
        </div>
        <div class="row">
          <div class="row" v-if="NewsGallery != []">
            <gallery :images="NewsGallery" :index="IndexGallery" @close="IndexGallery = null"
            :options="OptionGallery"
            ></gallery>
            <div class="image" v-for="(image, Index) in NewsGallery" :key="Index" @click="IndexGallery = Index"></div>
          </div>

          <div class="row">
          <div class="single-blog col-lg-4 col-md-4" v-for="(event, index) in GalleryList" :key="index">
            <div class="thumb">
              <img class="img-fluid" v-if="event.Pic !== null" :src="event.Pic" v-on:click="getEventGalleryList(event)"/>
              <img class="img-fluid" v-else src="./../../assets/img/image_not_available.png" />
            </div>
            <div class="bottom d-flex justify-content-between align-items-center flex-wrap">
              <div class="meta">{{event.Name}}</div>
            </div>
            <a href="#">
              <h4>{{event.Topic}}</h4>
            </a>
            <p>{{event.Desc}}</p>
          </div>
        </div>

        <div class="row">
          <div class="single-blog col-lg-4 col-md-4" v-for="(event, index) in VideoList" :key="index">
            <div class="thumb">
              <img class="img-fluid" v-if="event.Pic !== null" :src="event.Pic" v-on:click="showVideoModal(event.Name, event.VideoUrl)"/>
              <img class="img-fluid" v-else src="./../../assets/img/image_not_available.png" />
            </div>
            <div class="bottom d-flex justify-content-between align-items-center flex-wrap">
              <div class="meta">{{event.Name}}</div>
            </div>
            <a href="#">
              <h4>{{event.Topic}}</h4>
            </a>
            <p>{{event.Desc}}</p>
          </div>
        </div>
        </div>
      </div>
    </section>
    <!-- end blog Area -->

    <video-modal ref="myVideoModal"></video-modal>
  </div>
</template>

<script>
import axios from 'axios';
import conf from  "../../conf";
import Banner from '../Banner/BannerLis.vue';
import VueGallery from 'vue-gallery';
// import Slick from "vue-slick";
import VideoModel from "../../layout/VideoModal.vue";

export default {
  name: "home",
  components: {
    "app-banner":Banner,
    "gallery": VueGallery,
    "video-modal": VideoModel
  },
  data() {
    return {
      SerciveList: [],
      ProductList: [],
      EventList: [],
      GalleryList: [],
      NewsGallery: [],
      IndexGallery: null,
      IndexVideo: null,
      VideoGallery: [],
      VideoList: [],
      OptionGallery: null,
      PartnerList: [],
      StaticData: [],
      AboutText: "",
      VideoUrl: null,
      VideoDesc: null,
      QuotationItem: {
        Qid: null,
        Name: null,
        Tel: null,
        Email: null,
        Message: null,
        CreateDate: null
      },
      QuotationService: "0",
      QuotationSvKey: "0",
      slickPartnerOptions: {
        slidesToShow: 4,
        slidesToScroll:4,
        autoplay: true,
        infinite: true,
        speed: 300,
        dots: true
        // arrows: true
      }
    };
  },
  methods: {
    getServices() {
      axios.get(conf.ApiURL+`/Site/GetServiceList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.SerciveList = res.data;
              });
    },
    getProductList() {
      axios.get(conf.ApiURL+`/Site/GetCategoryList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.ProductList = res.data;
              });
    },
    // getEventList() {
    //   var event;
    //   event = {
    //     Topic: "บจก.ทีพีไอ โพลีน",
    //     Pic: "TPI_Visit.jpg",
    //     Desc: "บจก.ทีพีไอ โพลีน มาเยี่ยมเยือนบริษัทของเรา",
    //     Date: "01/01/2019"
    //   };
    //   this.EventList.push(event);
    //   event = {
    //     Topic: "บจก.กระเบื้องหลังคาตาเพชร",
    //     Pic: "กระเบื้องหลังคาตราเพชร_Visit.jpg",
    //     Desc: "บจก.กระเบื้องหลังคาตาเพชร มาเยี่ยมเยือนบริษัทของเรา",
    //     Date: "01/01/2019"
    //   };
    //   this.EventList.push(event);
    //   event = {
    //     Topic: "ผ้าป่าสามัคคี",
    //     Pic: "Activity_2.jpg",
    //     Desc: "ภาพกิจกรรมทำบุญถวายผ้าป่าสามัคคี",
    //     Date: "01/01/2019"
    //   };
    //   this.EventList.push(event);
    // },
    getEventList() {
      axios.get(conf.ApiURL+`/Site/GetNewsList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.EventList = res.data;
                this.GalleryList = [];
                this.VideoList = [];
                for(var i = 0; i < this.EventList.length; i++){
                  var Type = this.EventList[i].Type;
                  if(Type == "P")
                  {
                    if(this.GalleryList.length < 3)
                      this.GalleryList.push(this.EventList[i]);
                  }
                  else
                  {
                    if(this.VideoList.length < 3)
                      this.VideoList.push(this.EventList[i]);
                  }

                  if(this.VideoList.length == 3 && this.GalleryList.length == 3)
                    return;
                }

              });
    },
    getEventGalleryList(_Event) {
      if(_Event.Type == "P")
      {
        axios.get(conf.ApiURL+`/Site/GetNewsGallery?Nid=`+_Event.Nid, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                // console.log(res.data.Msg);
                this.NewsGallery = [];
                let Data = JSON.parse(res.data.Msg);
                for(var i = 0; i < Data.length; i++){
                  this.NewsGallery.push(Data[i].Pic);
                }

                if(this.NewsGallery.length > 0)
                  this.IndexGallery = 0;

                // console.log(this.NewsGallery);
                // this.NewsGallery = JSON.parse(res.data.Msg);
              });
      }
      else{
        this.NewsGallery = [];
        this.OptionGallery = null;
        if(_Event.VideoUrl != '' && _Event.VideoUrl != null){
        let _Video = {
          href: _Event.VideoUrl,
          type: 'text/html',
          youtube: _Event.VideoUrl.replace('https://www.youtube.com/watch?v=',''),
        }
        this.NewsGallery.push(_Video);

        if(this.NewsGallery.length > 0)
            this.IndexGallery = 0;
        }
      }
    },
    getVideoGalleryList(VideoUrl){
      // console.log(VideoUrl);
      this.IndexVideo = -1;
      this.VideoGallery = [];
      this.OptionGallery = {youTubeVideoIdProperty: 'youtube', youTubePlayerVars: undefined, youTubeClickToPlay: true};
      if(VideoUrl != '' && VideoUrl != null){
        let _Video = {
          href: VideoUrl,
          type: 'text/html',
          youtube: VideoUrl.replace('https://www.youtube.com/watch?v=',''),
        }
        this.VideoGallery.push(_Video);

        if(this.VideoGallery.length > 0)
          this.IndexVideo = 0;
      }
    },
    getPartnerList() {
      axios.get(conf.ApiURL+`/Site/GetPartnerList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.PartnerList = res.data;
              });
      // var partner;
      // partner = { Pic: "thai_airways_logo.png", Url: "" };
      // this.PartnerList.push(partner);
      // partner = { Pic: "bts_logo.png", Url: "" };
      // this.PartnerList.push(partner);
      // partner = { Pic: "SCG.png", Url: "" };
      // this.PartnerList.push(partner);
      // partner = { Pic: "tpipl_logo.png", Url: "" };
      // this.PartnerList.push(partner);
    },
    getAboutText()
    {
      setTimeout(() => {
        this.StaticData = this.$parent.getStaticData();
        if(this.StaticData.length > 0)
        {
          this.AboutText = this.StaticData[0].AboutHtml;
          this.VideoUrl = this.StaticData[0].VideoUrl;
          if(this.VideoUrl != null && this.VideoUrl != "")
          {
            this.VideoUrl = "https://www.youtube.com/embed/"+ this.VideoUrl.replace("https://www.youtube.com/watch?v=","");
          }
          this.VideoDesc = this.StaticData[0].VideoDesc;
        }
        else{
          this.getAboutText();
        }
      }, 500);
    },
    // resizeImageBaanner() {
    //   var window_width = window.innerWidth;
    //   var window_height = window.innerHeight;
    //   var header_height = $(".default-header").height();
    //   var header_height_static = $(".site-header.static").outerHeight();
    //   var fitscreen = window_height - header_height;

    //   $(".fullscreen").css("height", window_height);
    //   $(".fitscreen").css("height", fitscreen);
    // },
    serviceChange(e) {
        if(e.target.options.selectedIndex > -1) {
          this.QuotationService = e.target.options[e.target.options.selectedIndex].innerText;
          this.QuotationSvKey = e.target.options[e.target.options.selectedIndex].value;
        }
    },
    CreateNewQuataion(){
      let formData;
      if(this.QuotationService != "" && this.QuotationItem != "0"){
        formData = { 
          Qid: null,
          Name: this.QuotationItem.Name,
          Tel: this.QuotationItem.Tel,
          Email: this.QuotationItem.Email,
          Message: "บริการ:" + this.QuotationService + " ข้อความ:"+ this.QuotationItem.Message,
          CreateDate: null
        };

        const encodeForm = (data) => {
          return Object.keys(data)
              .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
              .join('&');
        };

        axios.post(conf.ApiURL+`/Setting/CreateNewQuatation`, 
                encodeForm(formData)
              )
              .then(res => {
                // var status = res.data.Status;
                var msg = res.data.Msg;
                this.QuotationItem = {
                                        Qid: null,
                                        Name: null,
                                        Tel: null,
                                        Email: null,
                                        Message: null,
                                        CreateDate: null
                                      };
                this.QuotationSvKey = "0";
                this.QuotationService = "0";

                this.$parent.showMsg("Bmroller.com", msg);
              });
      }
    },
    showVideoModal(Name, _VideoUrl){
      // console.log(_VideoUrl);
      _VideoUrl = "https://www.youtube.com/embed/"+ _VideoUrl.replace("https://www.youtube.com/watch?v=","");
      this.$refs.myVideoModal.showModal(Name, _VideoUrl);
    }
  },
  mounted() {
    this.getServices();
    this.getProductList();
    this.getEventList();
    this.getPartnerList();
    this.getAboutText();
  }
};
</script>

<style>
.content-html-home img{
  visibility: hidden;
  display: none;
}

.desc {
  white-space: pre-wrap; 
  word-wrap: break-word;
  font-family: inherit;
}

#service-select{
  overflow: hidden;
}

.partner-logo{
  width:100%;
  height:auto;
  overflow: hidden;
  white-space: nowrap;
}

.partner-logo .slide{
  /* max-width: 100%;
  height: auto;
  padding: 20px 20px; */
  width: 300%;
  animation: marquee 25s linear infinite;
}

.partner-logo img{
  max-width: 100%;
  height: auto;
  padding: 20px 20px;
  /* animation: marquee 1s linear infinite; */
}

.partner-logo .slide:hover {
  animation-play-state: paused
}

.card-deck{
  background: transparent;
  border: transparent;
}
.card-deck .card{
  background: transparent;
  border: transparent;
}

.card-partner{
  display: inline-block;
  margin-right: 4px;
}

.video-desc {
  white-space: pre-wrap; 
  word-wrap: break-word;
  font-family: inherit;
}

#container {
  height: 200px;
  width: 800px;
  border: 1px solid #333;
  overflow: hidden;
  margin: 25px auto;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Make it move */
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
</style>