<template>
  <div id="banners">
    <slick ref="slick" :options="slickOptions">
      <!-- <div class="banner-main" v-for="(item,index) in BannerList" :key="index"> -->
        <a :href="item.Link" class="banner-main" v-for="(item,index) in BannerList" :key="index">
          <img :src="item.Pic" />
        </a>
      <!-- </div> -->
    </slick>
  </div>
</template>

<script>
import axios from 'axios';
import Slick from "vue-slick";
import conf from  "../../conf";

export default {
  name: "Banner",
  components: {
    slick: Slick
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        autoplay: true,
        infinite: true,
        speed: 300,
        arrows: true
      },
      BannerList: []
    };
  },
  methods: {
    getBannerList() {
      axios.get(conf.ApiURL+`/Site/GetSlideList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                if(res != "" && res != null)
                {
                  this.BannerList = res.data;
                  this.reInit();
                  // console.log(this.BannerList);
                }
              });
    },
    next() {
      this.$refs.slick.next();
    },
    prev() {
      this.$refs.slick.prev();
    },
    reInit() {
      // Helpful if you have to deal with v-for to update dynamic lists
      // this.$nextTick(() => {
      //   this.$refs.slick.reSlick();
      // });
      let currIndex = this.$refs.slick.currentSlide();
      this.$refs.slick.destroy();
      this.$nextTick(() => {
          this.$refs.slick.create();
          this.$refs.slick.goTo(currIndex, true);
      })
    }
  },
  mounted() {
    this.getBannerList();
  }
};
</script>

<style>
/* #banners{
  padding-bottom: 50px;
} */
#banners{
  padding-top: 50px;
}

.banner-main img{
  width: 100%;
  height: auto;
}
</style>

