<template>
  <div>
    <section class="service-area section-gap" id="product">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text text-center">
            <h1 class="mb-10">{{cate}}</h1>
            <!-- <p>Our Product</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="(prd, index) in ProductList" :key="index">
            <router-link :to="{ name: 'product', params: { name: prd.Name }}">
                <div class="single-service">
                  <div class="thumb">
                      <img v-if="prd.Pic !== null" :src="require(`@/assets/img/products/${prd.Pic}`)" />
                      <img v-else src="./../../assets/img/image_not_available.png" />
                  </div>
                  <h4>{{prd.Name}}</h4>
                  <p v-if="prd.Desc !== null">{{prd.Desc}}</p>
                </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "Products",
  components: {
  },
  props:{
      cate: String
  },
  data() {
    return {
      Category:"",
      AllProduct:[],
      ProductList:[]
    };
  },
  methods: {
    LoadAllProduct(){
        var Prd;
        Prd = { Name:'ลูกกลิ้งยางสำหรับอุตสาหกรรมพลาสติกและฟิล์ม', Cate:'ลูกกลิ้งยางอุตสาหกรรม', Pic:'roller_plastic_film.jpeg', Desc:null};
        this.AllProduct.push(Prd);
    },
    getProductByCategory(){
        this.ProductList = [];
        this.AllProduct.forEach(item => {
            if(item.Cate == this.cate){
                this.ProductList.push(item);
            }
        });
    }
  },
  mounted() {
    this.cate = this.$route.params.cate;
    this.LoadAllProduct();
    this.getProductByCategory();
  }
};
</script>
