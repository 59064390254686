<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <card shadow type="secondary">
                <div slot="header" class="bg-white border-0">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">Setting</h3>
                        </div>
                        <div class="col-4 text-right">
                            <base-button type="info"  icon="ni ni-ruler-pencil" v-on:click="saveStaticData()">Save</base-button>
                        </div>
                    </div>
                </div>

                <h6 class="heading-small text-muted mb-4">Contact</h6>
                <div class="row">
                  <div class="col-lg-6">
                      <base-input alternative="" label="ชื่อบริษัท" placeholder="Company Name" v-model="siteData.CompanyName" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-6">
                      <base-input alternative="" label="ชื่อบริษัท(ภาษาไทย)" placeholder="Company Name Thai" v-model="siteData.CompanyNameTh" input-classes="form-control-alternative" />
                  </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <base-input alternative="" label="ที่อยู่">
                                <textarea rows="4" class="form-control form-control-alternative" placeholder="Address..." v-model="siteData.Address" ></textarea>
                            </base-input>
                        </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                      <base-input alternative="" label="โทรศัพท์" placeholder="Phone" v-model="siteData.Phone" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-6">
                      <base-input alternative="" label="Fax" placeholder="Fax" v-model="siteData.Fax" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-12">
                      <base-input alternative="" label="อีเมล์" placeholder="E-Mail" v-model="siteData.Email" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-6">
                      <base-input alternative="" label="Facebook" placeholder="Facebook" v-model="siteData.Facebook" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-6">
                      <base-input alternative="" label="Line" placeholder="Line" v-model="siteData.Line" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-6">
                      <base-input alternative="" label="Email(Quatation)" placeholder="Email(Quatation) GMail only" v-model="siteData.EmailSystem" input-classes="form-control-alternative" />
                  </div>
                  <div class="col-lg-6">
                      <base-input alternative="" label="Password(Email)" placeholder="Password(Email)" v-model="siteData.EmailSystemPw" input-classes="form-control-alternative" type="password" />
                  </div>
                  <div class="col-lg-12">
                      <base-input alternative="" label="Video Url" placeholder="Youtube Link" v-model="siteData.VideoUrl" input-classes="form-control-alternative" />
                      <div class="form-group">
                          <base-input alternative="" label="Video Description">
                              <textarea rows="3" class="form-control form-control-alternative" placeholder="Description..." v-model="siteData.VideoDesc" ></textarea>
                          </base-input>
                      </div>
                  </div>
                </div>
                <hr class="my-4" />
                <div>
                  <label>Map Picture</label>
                  <div class="banner_preview">
                    <img v-if="MapPic !== null && MapPic !== 'null'" :src="MapPic" class="img-modal" />
                  </div>
                  <br/>
                  <input type="file" ref="fileInput" @change="onMappicFileChange" class="file_upload-modal" />
                </div>
                
                <hr class="my-4" />
                <h6 class="heading-small text-muted mb-4">About Page</h6>
                <div class="bg-white contacthtml">
                  <vue-editor v-model="siteData.AboutHtml" />
                </div>
                <hr class="my-4" />
                <h6 class="heading-small text-muted mb-4">Banners</h6>
                <div>
                  <label>About Banner</label>
                  <div class="banner_preview">
                    <img v-if="AboutBanner !== null && AboutBanner !== 'null'" :src="AboutBanner" class="img-modal" />
                  </div>
                  <br/>
                  <input type="file" ref="fileInput" @change="onAboutBannerFileChange" class="file_upload-modal" />
                </div>
                <hr class="my-4" />
                <!-- <div>
                  <label>Product Banner</label>
                  <div class="banner_preview">
                    <img v-if="ProductBanner !== null && ProductBanner !== 'null'" :src="ProductBanner" class="img-modal" />
                  </div>
                  <br/>
                  <input type="file" ref="fileInput" @change="onProductBannerFileChange" class="file_upload-modal" />
                </div> -->
                <!-- <hr class="my-4" /> -->
                <div>
                  <label>Service Banner</label>
                  <div class="banner_preview">
                    <img v-if="ServiceBanner !== null && ServiceBanner !== 'null'" :src="ServiceBanner" class="img-modal" />
                  </div>
                  <br/>
                  <input type="file" ref="fileInput" @change="onServiceBannerFileChange" class="file_upload-modal" />
                </div>
                <hr class="my-4" />
                <div>
                  <label>News Banner</label>
                  <div class="banner_preview">
                    <img v-if="NewsBanner !== null && NewsBanner !== 'null'" :src="NewsBanner" class="img-modal" />
                  </div>
                  <br/>
                  <input type="file" ref="fileInput" @change="onNewsBannerFileChange" class="file_upload-modal" />
                </div>
                <hr class="my-4" />
                <div>
                  <label>Contact Banner</label>
                  <div class="banner_preview">
                    <img v-if="ContactBanner !== null && ContactBanner !== 'null'" :src="ContactBanner" class="img-modal" />
                  </div>
                  <br/>
                  <input type="file" ref="fileInput" @change="onContactBannerFileChange" class="file_upload-modal" />
                </div>
                <hr class="my-4" />
            </card>
        </div>
    </div>
</template>

<script>
  import axios from 'axios';
  import conf from  "../conf";
  import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

  export default {
    name: 'staticdata',
    components: {
      'vue-editor': VueEditor
    },
    data() {
        return {
            Token: "",
            html_about: "",
            type: "light",
            siteData: {
              Id: 1,
              CompanyName: "",
              CompanyNameTh: "",
              Address: "",
              Phone: "",
              Fax: "",
              Email: "",
              Facebook: "",
              Line: "",
              Abouthtml: "",
              EmailSystem: "",
              EmailSystemPw: "",
              Lastupdate: null,
              VideoUrl: "",
              VideoDesc: ""
            },
            AboutBanner: null,
            ServiceBanner: null,
            ProductBanner: null,
            NewsBanner: null,
            ContactBanner: null,
            MapPic: null,
        }        
    },
    methods:{
      getStaticData(){
        axios.get(conf.ApiURL+`/Setting/GetStaticData`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                var msg = res.data.Msg;
                if(Status == "SUCCESS")
                {
                  let Data = JSON.parse(msg);
                  this.siteData = Data[0];
                  if(this.siteData.AboutBanner != null && this.siteData.AboutBanner != "null")
                    this.AboutBanner = conf.ApiURL+`/uploads/banners/`+this.siteData.AboutBanner;
                  if(this.siteData.ServiceBanner != null && this.siteData.ServiceBanner != "null")
                    this.ServiceBanner = conf.ApiURL+`/uploads/banners/`+this.siteData.ServiceBanner;
                  if(this.siteData.NewsBanner != null && this.siteData.NewsBanner != "null")
                    this.NewsBanner = conf.ApiURL+`/uploads/banners/`+this.siteData.NewsBanner;
                  if(this.siteData.ContactBanner != null && this.siteData.ContactBanner != "null")
                    this.ContactBanner = conf.ApiURL+`/uploads/banners/`+this.siteData.ContactBanner;

                  if(this.siteData.MapPic != null && this.siteData.MapPic != "null")
                    this.MapPic = conf.ApiURL+`/uploads/`+this.siteData.MapPic;
                }
              });
      },
      saveStaticData(){
        // console.log(this.siteData);
        if(this.siteData == null || this.siteData == 'undefined' || this.siteData == {})
          return;

        let formData = { 
            Id: 1,
            CompanyName: this.siteData.CompanyName,
            CompanyNameTh: this.siteData.CompanyNameTh,
            Address: this.siteData.Address,
            Phone: this.siteData.Phone,
            Fax: this.siteData.Fax,
            Email: this.siteData.Email,
            Facebook: this.siteData.Facebook,
            Line: this.siteData.Line,
            Abouthtml: this.siteData.AboutHtml,
            EmailSystem: this.siteData.EmailSystem,
            EmailSystemPw: this.siteData.EmailSystemPw,
            Lastupdate: null,
            VideoUrl: this.siteData.VideoUrl,
            VideoDesc: this.siteData.VideoDesc
        };

        const encodeForm = (data) => {
          return Object.keys(data)
              .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
              .join('&');
        };

        axios.put(conf.ApiURL+`/Setting/UpdateStaticData`, encodeForm(formData))
              .then(res => {
                var status = res.data.Status;
                var msg = res.data.Msg;
                if(status === "SUCCESS"){
                  this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                }
                else{
                  this.showNotify(msg,"danger");
                }
              });
      },
      showNotify(_msg, _type = "success"){
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
      },
      onAboutBannerFileChange(e){
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.AboutBanner = URL.createObjectURL(file);
        this.uploadImg("About");
      },
      // onProductBannerFileChange(e){
      //   const file = e.target.files[0];
      //   this.uploadFile = e.target.files[0];
      //   this.AboutBanner = URL.createObjectURL(file);
      //   this.uploadImg("Product");
      // },
      onServiceBannerFileChange(e){
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.ServiceBanner = URL.createObjectURL(file);
        this.uploadImg("Service");
      },
      onNewsBannerFileChange(e){
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.NewsBanner = URL.createObjectURL(file);
        this.uploadImg("News");
      },
      onContactBannerFileChange(e){
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.ContactBanner = URL.createObjectURL(file);
        this.uploadImg("Contact");
      },
      uploadImg(ItemKey){
        let formData = new FormData();
        formData.append("Page", ItemKey);
        formData.append("ImgFile", this.uploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadBannerImage', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
            })
            .then(res => {
              let status = res.data.Status;
              if(status != "SUCCESS")
                this.showNotify(res.data.Msg,"danger");
              else
                this.showNotify(res.data.Msg,"success");
            });
      },
      onMappicFileChange(e){
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.MapPic = URL.createObjectURL(file);

        let formData = new FormData();
        // formData.append("Page", ItemKey);
        formData.append("ImgFile", this.uploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadMapImage', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
            })
            .then(res => {
              let status = res.data.Status;
              if(status != "SUCCESS")
                this.showNotify(res.data.Msg,"danger");
              else
                this.showNotify(res.data.Msg,"success");
            });
      }
    },
    mounted(){
      this.getStaticData();
    }
  };
</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.contacthtml .ql-editor { 
  max-height:400px 
}

.banner_preview img{
  width: 100%;
  height: auto;
}

</style>
