<template>
  <div class="card shadow"
       :class="type === 'dark' ? 'bg-default': ''">
    <div class="card-header border-0"
         :class="type === 'dark' ? 'bg-transparent': ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
            Services
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary"  icon="fa fa-plus" v-on:click="showModalNewService()">Create</base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush"
                  :class="type === 'dark' ? 'table-dark': ''"
                  :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                  tbody-classes="list"
                  :data="tableData">

        <template slot="columns">
          <th>CMD</th>
          <th>Picture</th>
          <th>Name</th>
          <th>Description</th>
        </template>

        <template slot-scope="{row}">
          <td>
            <base-button type="info" icon="fa fa-edit" v-on:click="showModalEditService(row)" ></base-button>
            <base-button type="danger" icon="fa fa-trash" v-on:click="deleteService(row.Sid)" ></base-button>
          </td>
          <td>
            <a href="#" class="avatar avatar-md" data-toggle="tooltip" data-original-title="">
                <img v-if="row.Pic !== 'null'" :src="ApiURL+'/uploads/services/' + row.Pic">
            </a>
          </td>
          <td>
            <div class="media-body">
                <span class="name mb-0 text-sm">{{row.Name}}</span>
            </div>
          </td>
          <td>
            <div class="media-body">
                <span class="name mb-0 text-sm">{{row.Description}}</span>
            </div>
          </td>
        </template>
      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent': ''">
      <base-pagination :total="totalPage" ref="pageMenu"></base-pagination>
    </div>

    <!-- Modal -->
    <modal :show.sync="modalEdit"
            body-classes="p-0"
            modal-classes="modal-dialog-centered modal-lg">

            <card shadow type="secondary">
                <div slot="header" class="bg-white border-0">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <h3 class="mb-0">Edit Service</h3>
                        </div>
                        <div class="col-4 text-right">
                            <base-button type="danger"  icon="ni ni-user-run" @click="modalEdit = false">Close</base-button>
                        </div>
                    </div>
                </div>
                <div>
                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-lg-4">
                        <label>Pic</label>
                        <div id="preview">
                          <img v-if="picurl !== null && picurl !== 'null'" :src="picurl" />
                        </div>
                        <input type="file" ref="fileInput" @change="onFileChange" />
                        <!-- <file-input ref="fileInput" @change="onFileChange"></file-input> -->
                      </div>
                      <div class="col-lg-8">
                          <div class="form-group">
                              <input type="hidden" v-model="service.Sid" />
                              <base-input label="ชื่อบริการ" placeholder="Service Name" v-model="service.Name"></base-input>
                          </div>
                          <div class="form-group">
                              <base-input alternative=""
                                          label="รายละเอียด">
                                  <textarea rows="4" class="form-control form-control-alternative" placeholder="Service Description" v-model="service.Description"></textarea>
                              </base-input>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div slot="footer" class="bg-white border-0">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <!-- <base-button type="info"  icon="ni ni-ruler-pencil">Save</base-button> -->
                        </div>
                        <div class="col-4 text-right">
                            <base-button type="info"  icon="ni ni-ruler-pencil" v-on:click="saveService()">Save</base-button>
                        </div>
                    </div>
                </div>
            </card>
    </modal>
    <!-- Modal -->
  </div>
</template>

<script>
  import axios from 'axios';
  import conf from  "../conf";

  export default {
    name: 'services-table',
    props: {
      type: {
        type: String
      },
      title: String
    },
    data() {
      return {
        minIndex: 1,
        maxIndex: 1,
        modalEdit: false,
        currentPage: 1,
        allData:[],
        tableData:[],
        totalPage:11,
        picurl: null,
        uploadFile: null,
        ApiURL: '',
        service: {
          Sid: '',
          Name: '',
          Description: '',
          Pic: '',
          HtmlDesc: '',
          LastUpdate: null
        }
      }
    },
    methods:{
      onFileChange(e) {
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.picurl = URL.createObjectURL(file);
      },
      resetFileInput() {
        const input = this.$refs.fileInput
        input.type = 'text'
        input.type = 'file'
      },
      showModalNewService(){
        this.resetFileInput();
        this.service = {Sid:null, Name:null, Description:null, Pic:null};
        this.picurl = null;
        this.modalEdit = true;
      },
      showModalEditService(_service){
        this.resetFileInput();
        this.service = _service;
        if(_service.Pic !== null && _service.Pic !== 'null')
          this.picurl = conf.ApiURL+"/uploads/services/"+_service.Pic;
        else
          this.picurl = null;

        this.modalEdit = true;
      },
      getServiceList(){
        axios.get(conf.ApiURL+`/Setting/GetServices`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.allData = JSON.parse(res.data.Msg);
                  this.$refs.pageMenu.pageCount = 0;
                  this.$refs.pageMenu.setTotalRow(this.allData.length);
                  this.$refs.pageMenu.totalPages;
                  this.$refs.pageMenu.defaultPagesToDisplay = Math.ceil(this.allData.length / 10);
                  // console.log(this.$refs.pageMenu.defaultPagesToDisplay);
                  this.showServiceList(this.currentPage);
                }
              });
      },
      showServiceList(page){
        let maxLength = page * 10;
        let minLength = ((page * 10))-10;
        if(maxLength > this.allData.length-1)
          maxLength = this.allData.length;
        
        this.tableData = [];
        for(let i = minLength; i < maxLength; i++){
          this.tableData.push(this.allData[i]);
        }
      },
      saveService(){
        let formData;
        if(this.service.Sid !== null && this.service.Sid !== ''){
          formData = { Sid: this.service.Sid, Name: this.service.Name, Description: this.service.Description };

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.put(conf.ApiURL+`/Setting/UpdateService`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  if(status === "SUCCESS"){
                    this.modalEdit = false;
                    this.getServiceList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                });

          if(this.uploadFile !== null){
            formData = new FormData();
            formData.append("Sid", this.service.Sid);
            formData.append("ImgFile", this.uploadFile);
            axios.post(conf.ApiURL+'/Setting/UploadServiceImage', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
                })
                .then(res => {
                  let status = res.data.Status;
                  if(status != "SUCCESS")
                    this.showNotify(res.data.Msg,"danger");
                  // console.log(res.data);
                });
          }
        }
        else{
          formData = { Name: this.service.Name, Description: this.service.Description, Pic: null};

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.post(conf.ApiURL+`/Setting/CreateService`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    var item = JSON.parse(msg);
                    // console.log(item);
                    if(this.uploadFile !== null){
                      var formData = new FormData();
                      formData.append("Sid", item.Key);
                      formData.append("ImgFile", this.uploadFile);
                      axios.post(conf.ApiURL+'/Setting/UploadServiceImage', formData, {
                          headers: {
                            'Content-Type': 'multipart/form-data'
                          }
                          })
                          .then(res => {
                            status = res.data.Status;
                            // console.log(res.data);
                          });
                    }

                    this.modalEdit = false;
                    this.getServiceList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });
        }
      },
      deleteService(Sid){
        axios.delete(conf.ApiURL+`/Setting/DeleteService`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Sid: Sid
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  this.modalEdit = false;
                  this.getServiceList();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      },
      showNotify(_msg, _type = "success"){
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
      }
    },
    mounted(){
      this.ApiURL = conf.ApiURL;
      this.getServiceList();
    }
  }
</script>

<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
