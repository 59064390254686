<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <!-- <base-button @click="$notify({type: 'primary', message: 'Primary Notification'})">Primary</base-button> -->
                <div class="col">
                    <services-table title="Light Table"></services-table>
                </div>
            </div>
            <!-- <div class="row mt-5">
                <div class="col">
                    <services-table type="dark" title="Dark Table"></services-table>
                </div>
            </div> -->
        </div>

    </div>
</template>
<script>
  import ServicesTable from './ServicesTable'
  export default {
    name: 'tables',
    components: {
      ServicesTable
    }
  };
</script>
<style></style>
