<template>
  <div>
    <img v-if="AboutBanner != null" :src="AboutBanner" class="main-banner-img" />
    <section class="contact-page-area section-gap" id="about">
      <div class="container">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8 col-md-12 home-about-left">
            <div class="content-html" v-html="AboutText"></div>
            <!-- {{AboutText}} -->
            <!-- <h1>เกี่ยวกับเรา</h1> -->
            <!-- <img src="../assets/img/company.jpg" class="about-img" /> -->
            
            <!-- <p class="sub">ห้างหุ้นส่วนจำกัด บุญรัศมีการยาง</p>
            <p class="pb-20">
              ได้ก่อตั้งขึ้นเมื่อปี พ.ศ.2543 เพื่อดำเนินธุรกิจในด้านการผลิตลูกกลิ้งยางอุตสาหกรรม และอะไหล่ยางสำหรับเครื่องจักรทุกชนิด โดยมีวัตถุประสงค์หลักในการผลิตลูกกลิ้งยางสำหรับอุตสาหกรรมทั้งขนาดเล็กและขนาดใหญ่ สำหรับงานพิมพ์พลาสติก พิมพ์แผ่นฟิล์ม พิมพ์แผ่นโลหะ พิมพ์กระดาษ โรงงานสิ่งทอ ฟอกย้อม โรงงานเคลือบผิวไม้ อัดเงา อาบแลคเกอร์ ลูกกลิ้งลำเลียงทุกชนิด ตลอดจนอุตสาหกรรมอื่น ๆ ที่ต้องใช้ลูกกลิ้งยางเป็นส่วนสำคัญ
              ต่อมาในปี พ.ศ.2546 คณะผู้บริหารได้เปิดบริษัทขึ้นอีก 1 แห่งที่พร้อมจะให้การบริการแก่ลูกค้าได้ครอบคลุมมากยิ่งขึ้นในทุกวงการอุตสาหกรรมในนาม
            </p>
            <p class="sub">บริษัท บีอาร์เอ็ม อินเตอร์เนชั่นแนล จำกัด</p>
            <p class="pb-20">
              ซึ่งบริหารงานโดยบุคลากรที่มีประสบการณ์ในอุตสาหกรรมลูกกลิ้งยางมานานกว่า 20 ปี
              เรามีความมุ่งมั่นอย่างที่สุดที่จะเป็นผู้ผลิตสินค้าที่มีคุณภาพสูงกว่ามาตรฐาน นอกจากสูตรส่วนผสมในการผลิตยางที่มีการพัฒนาให้เหมาะสมกับอุตสาหกรรมต่าง ๆ อยู่ตลอดเวลาแล้ว ยิ่งไปกว่านั้น เรายังตระหนักถึงการบริการที่สะดวก รวดเร็ว ตรงเวลา และมีประสิทธิภาพ สินค้าที่ผลิตขึ้นสามารถรับประกันได้ทุกชิ้นงาน เพื่ออุตสาหกรรมที่หลากหลายของท่าน
            </p> -->
            <!-- <a class="primary-btn" disabled href="#">ผู้เชี่ยวชาญด้านลูกกลิ้งยาง</a> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "About",
  components: {},
  data() {
    return {
      AboutText: "",
      StaticData: [],
      AboutBanner: null
    };
  },
  methods: {
    getAboutText(){
      setTimeout(() => {
        this.StaticData = this.$parent.getStaticData();
        if(this.StaticData.length == 1)
        {
          this.AboutText = this.StaticData[0].AboutHtml;
          this.AboutBanner = this.StaticData[0].AboutBanner;
        }
        else{
          this.getAboutText();
        }
      }, 500);
    }
  },
  mounted() {
    this.getAboutText();
  }
};
</script>

<style>
.content-html img{
  width: 100%;
  height: auto;
}
</style>