<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <!-- <services-table title="Light Table"></services-table> -->
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="mb-0">{{title}}</h3>
                                </div>
                                <div class="col text-right">
                                    <base-button type="primary"  icon="fa fa-plus" v-on:click="showModalNew()">Create</base-button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                        <base-table class="table align-items-center table-flush" thead-classes="thead-light" tbody-classes="list" :data="tableData">

                            <template slot="columns">
                                <th>CMD</th>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Gallery</th>
                            </template>

                            <template slot-scope="{row}">
                                <td>
                                    <base-button type="info" icon="fa fa-edit" v-on:click="showModalEdit(row)" ></base-button>
                                    <base-button type="danger" icon="fa fa-trash" v-on:click="deleteData(row.Nid)" ></base-button>
                                </td>
                                <td>
                                    <a href="#!" class="avatar" data-toggle="tooltip" data-original-title="">
                                        <img v-if="row.Pic !== 'null'" :src="ApiURL+'/uploads/news/' + row.Pic">
                                    </a>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Name}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <!-- <span class="name mb-0 text-sm">{{row.Description}}</span> -->
                                    </div>
                                </td>
                            </template>
                        </base-table>
                        </div>

                        <div class="card-footer d-flex justify-content-end">
                            <base-pagination :total="totalPage" ref="pageMenu"></base-pagination>
                        </div>

                        <!-- Modal -->
                        <modal :show.sync="modalEdit"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-xl">

                                <card shadow type="secondary">
                                    <div slot="header" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <h3 class="mb-0">Edit {{title}}</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="danger"  icon="ni ni-user-run" @click="modalEdit = false">Close</base-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                          <div class="col-lg-12">
                                          <tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="">
                                              <tab-pane id="home">
                                                  <span slot="title">หน้าหลัก</span>
                                                  
                                                  <div class="row">
                                                    <div class="col-lg-4">
                                                        <label>Pic</label>
                                                        <div id="preview">
                                                        <img v-if="picurl !== null && picurl !== 'null'" :src="picurl" class="img-modal" />
                                                        </div>
                                                        <input type="file" ref="fileInput" @change="onFileChange" class="file_upload-modal" />
                                                        <!-- <file-input ref="fileInput" @change="onFileChange"></file-input> -->
                                                    </div>
                                                    <div class="col-lg-8">
                                                        <div class="form-group">
                                                            <input type="hidden" v-model="item.Nid" />
                                                            <base-input label="หัวข้อ" placeholder="Topic" v-model="item.Name"></base-input>
                                                        </div>
                                                        <div class="form-group">
                                                          <label>ประเภทข้อมูล</label>
                                                          <base-radio name="P" class="mb-3" v-model="item.Type">Picture</base-radio>
                                                          <base-radio name="V" class="mb-3" v-model="item.Type">Video</base-radio>
                                                        </div>
                                                        <div class="form-group" v-if="item.Type == 'V'">
                                                            <base-input label="VideoUrl" placeholder="VideoUrl *YouTube*" v-model="item.VideoUrl"></base-input>
                                                        </div>
                                                    </div>
                                                  </div>
                                              </tab-pane>

                                              <tab-pane v-if="item.Type == 'P'" id="gallery">
                                                  <span slot="title" >คลังภาพ</span>
                                                  <div>
                                                    <div class="row">
                                                      <!-- <div class="col-lg-12"> -->
                                                        <!-- <div class="card-deck"> -->
                                                          <div class="col-sm-4 col-md-3 col-lg-3">
                                                            <card class="card">
                                                              <input type="file" ref="fileNewGallery" style="display: none" v-on:change="UploadImageGallery">
                                                              <div class="text-center" v-on:click="AddNewImageGallery">
                                                                <img class="card-img card-img-new" src="../assets/plus.png" alt="">
                                                                <div class="pt-2"> 
                                                                  <h5>New File</h5>
                                                                </div>
                                                              </div>
                                                            </card>
                                                          </div>

                                                          <div class="col-sm-4 col-md-3 col-lg-3" v-for="(g,index) in listGallery" :key="index">
                                                            <!-- <card v-for="(g,index) in listGallery" :key="index" class="card "> -->
                                                            <card class="card">
                                                              <img class="card-img" :src="g.Pic" alt="">
                                                              <div class="pt-2"> 
                                                                <div class="float-right">
                                                                  <base-button type="danger" v-on:click="DeleteImageGallery(g.Item)"><i class="fa fa-trash"></i></base-button>
                                                                </div>
                                                              </div>
                                                            </card>      
                                                          </div>                                                    
                                                        <!-- </div> -->
                                                      <!-- </div> -->
                                                    </div>
                                                  </div>
                                              </tab-pane>
                                          </tabs>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div slot="footer" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <!-- <base-button type="info"  icon="ni ni-ruler-pencil">Save</base-button> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="info"  icon="ni ni-ruler-pencil" v-on:click="saveData()">Save</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                        </modal>
                        <!-- Modal -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
  import axios from 'axios';
  import conf from  "../conf";
  import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";

  export default {
    name: 'news-page',
    props: {      
    //   title: String
    },
    components: {
      // VueEditor
    },
    data() {
      return {
        title: "News",
        minIndex: 1,
        maxIndex: 1,
        modalEdit: false,
        currentPage: 1,
        allData:[],
        tableData:[],
        totalPage:11,
        picurl: null,
        uploadFile: null,
        fileMultiple: [],
        ApiURL: '',
        item: {
          Nid: '',
          Name: '',
          HtmlDesc: '',
          Pic: '',
          LastUpdate: null,
          VideoUrl: '',
          Type: 'P'
        },
        listGallery:[]
      }
    },
    methods:{
      onFileChange(e) {
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.picurl = URL.createObjectURL(file);
      },
      resetFileInput() {
        const input = this.$refs.fileInput;
        input.type = 'text';
        input.type = 'file';
      },
      showModalNew(){
        this.resetFileInput();
        this.item = {Nid:null, Name:null, HtmlDesc:null, Pic:null, Type:'P'};
        this.picurl = null;
        this.modalEdit = true;
        this.listGallery = [];
      },
      showModalEdit(_item){
        this.resetFileInput();
        this.item = _item;
        if(_item.Pic !== null && _item.Pic !== 'null')
          this.picurl = conf.ApiURL+"/uploads/news/"+_item.Pic;
        else
          this.picurl = null;

        this.GetImageGallery();
        this.modalEdit = true;
      },
      getDataList(){
        axios.get(conf.ApiURL+`/Setting/GetNews`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.allData = JSON.parse(res.data.Msg);
                  this.$refs.pageMenu.pageCount = 0;
                  this.$refs.pageMenu.setTotalRow(this.allData.length);
                  this.$refs.pageMenu.totalPages;
                  this.$refs.pageMenu.defaultPagesToDisplay = Math.ceil(this.allData.length / 10);
                  // console.log(this.$refs.pageMenu.defaultPagesToDisplay);
                  this.showDataList(this.currentPage);
                }
              });
      },
      showDataList(page){
        let maxLength = page * 10;
        let minLength = ((page * 10))-10;
        if(maxLength > this.allData.length-1)
          maxLength = this.allData.length;
        
        this.tableData = [];
        for(let i = minLength; i < maxLength; i++){
          this.tableData.push(this.allData[i]);
        }
      },
      saveData(){
        let formData;
        if(this.item.Nid !== null && this.item.Nid !== ''){
          formData = { Nid: this.item.Nid, Name: this.item.Name, HtmlDesc: this.item.HtmlDesc, Type: this.item.Type, VideoUrl: this.item.VideoUrl };

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.put(conf.ApiURL+`/Setting/UpdateNews`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });

          if(this.uploadFile !== null){
            this.uploadImg(this.item.Nid);
          }
        }
        else{
          formData = { Name: this.item.Name, HtmlDesc: this.item.HtmlDesc, Pic: null};

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.post(conf.ApiURL+`/Setting/CreateNews`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    var _item = JSON.parse(msg);
                    // console.log(item);
                    if(this.uploadFile !== null){
                      this.uploadImg(_item.Key);
                    }

                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });
        }
      },
      deleteData(_Id){
        axios.delete(conf.ApiURL+`/Setting/DeleteNews`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Nid: _Id
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  this.modalEdit = false;
                  this.getDataList();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      },
      uploadImg(ItemKey){
        let formData = new FormData();
        formData.append("Nid", ItemKey);
        formData.append("ImgFile", this.uploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadNewsImage', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(res => {
                let status = res.data.Status;
                let msg = res.data.Msg;
                if(status != "SUCCESS")
                  this.showNotify(msg,"danger");                
              });
      },
      showNotify(_msg, _type = "success"){
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
      },
      AddNewImageGallery(){
        this.$refs.fileNewGallery.click();
      },
      GetImageGallery(){
        axios.get(conf.ApiURL+`/Setting/GetNewsGallery?Nid=`+this.item.Nid, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.listGallery = JSON.parse(res.data.Msg);
                  // console.log(this.listGallery);
                }
              });
      },
      UploadImageGallery(e)
      {
        if(this.item.Nid == null || this.item.Nid == '')
        {
          this.showNotify("กรุณาบันทึกข้อมูลก่อนอัพเดทรูปภาพ","danget");
          return;
        }

        let _UploadFile = e.target.files[0];

        if(_UploadFile == null)
          return;

        let formData = new FormData();
        formData.append("Nid", this.item.Nid);
        formData.append("ImgFile", _UploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadNewsGalleryImage', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(res => {
                let status = res.data.Status;
                let msg = res.data.Msg;
                if(status != "SUCCESS")
                  this.showNotify(msg,"danger");   
                  this.GetImageGallery();             
              });
        // this.picurl = URL.createObjectURL(file);
      },
      DeleteImageGallery(_Item){
        axios.delete(conf.ApiURL+`/Setting/DeleteNewsGalleryImage`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Nid: this.item.Nid,
                  Item: _Item
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  // this.modalEdit = false;
                  // this.getDataList();
                  this.GetImageGallery();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      }
    },
    mounted(){
      this.ApiURL = conf.ApiURL;
      this.getDataList();
    }
  }
</script>

<style>
@import "~vue2-editor/dist/vue2-editor.css";

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.contacthtml .ql-editor { 
  max-height:400px 
}

.img-modal{
  width: 100%;
  height: auto;
}

.file_upload-modal{
  width: 100%;
  overflow: hidden;
}

.card-img-new{
  width: 70%;
  height: auto;
}

/* .btn-new-file{
  padding: 50px 50px;
  font-size: 50px;
  display: flex; 
  justify-content: center;
  vertical-align: middle;
} */

</style>
