<template>
  <div>
    <app-banner></app-banner>
    <br/>
    <section class="service-area section-gap" id="product">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text text-center">
            <h1 class="mb-10">สินค้าของเรา</h1>
            <!-- <p>Our Product</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="(prd, index) in ProductList" :key="index">
            <!-- <router-link :to="{ name: 'productbycate', params: { cate: prd.Name }}"> -->
            <router-link :to="{ name: 'product', params: { name: prd.Name }}">
              <div class="single-service">
                <div class="thumb">
                  <!-- <img v-if="prd.Pic !== null" :src="require(`@/assets/img/products/${prd.Pic}`)" /> -->
                  <img v-if="prd.Pic !== null" :src="prd.Pic" />
                  <img v-else src="./../../assets/img/image_not_available.png" />
                </div>
                <h4>{{prd.Name}}</h4>
                <p v-if="prd.Desc !== null">{{prd.Desc}}</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import conf from  "../../conf";
import Banner from '../Banner/BannerLis.vue';

export default {
  name: "Products",
  components: {
    "app-banner": Banner
  },
  data() {
    return {
      ProductList:[]
    };
  },
  methods: {
    // getProductList(){
    //   var Prd;
    //   Prd = {Name:'ลูกกลิ้งยางอุตสาหกรรม', Pic:'ลูกกลิ้งยางอุตสาหกรรม.jpg', Desc:null};
    //   this.ProductList.push(Prd);
    //   Prd = {Name:'ลูกกลิ้งโพลียูลียูริเทรน', Pic:'ลูกกลิ้งโพลียูริเทน.jpg', Desc:null};
    //   this.ProductList.push(Prd);
    //   Prd = {Name:'ลูกกลิ้งซิลิโคน', Pic:'ลูกกลิ้งซิลิโคน.jpg', Desc:null};
    //   this.ProductList.push(Prd);
    //   Prd = {Name:'ลูกกลิ้งโค้ง', Pic:'ลูกกลิ้งโค้ง.jpg', Desc:null};
    //   this.ProductList.push(Prd);
    //   Prd = {Name:'ชิ้นส่วนอะไหล่-อุปกรณ์', Pic:'ชิ้นส่วนอะไหล่_อุปกรณ์.jpg', Desc:null};
    //   this.ProductList.push(Prd);
    // },
    getDataList(){
        axios.get(conf.ApiURL+`/Site/GetCategoryList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.ProductList = res.data;
              });
      }
  },
  mounted() {
    this.getDataList();
  }
};
</script>
