<template>
    <div>

      <!-- Modal -->
      <div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="videoModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="videoModalLabel">{{Name}}</h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="videoWrapper">
                <iframe width="560" height="315" :src="VideoUrl" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
            <!-- <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
  name: 'video-modal',
  components: {
  },
  data(){
    return{
      VideoUrl: null,
      Name: "",
      modalActive: false
    };
  },
  methods: {
    showModal(_Name, _VideoUrl){
      this.Name = _Name;
      this.VideoUrl = _VideoUrl;
      $('#videoModal').modal('show');
    }
  },
  mounted(){
    
  }
}
</script>

<style>
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
</style>