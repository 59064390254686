<template>
  <div>
    <!-- <img v-if="ProductBanner != null" :src="ProductBanner" class="main-banner-img" /> -->
    <app-banner></app-banner>
    <br/>
    <section class="service-area section-gap" id="product">
      <div class="container">
        <!-- <div class="row justify-content-center"> -->
          <div class="row">
            <div class="col-lg-12" v-html="PRDContent"></div>
          </div>       

          <div class="row" v-if="GalleryList != []">
            <gallery :images="GalleryList" :index="IndexGallery" @close="IndexGallery = null"></gallery>
            <div class="col-lg-3 img-list image" v-for="(image, Index) in GalleryList" :key="Index" @click="IndexGallery = Index">
              <img :src="image" class="img-gallery" />
            </div>
          </div>
        </div>
      <!-- </div> -->
    </section>
  </div>
</template>

<style>
  .indent-default{
    text-indent: 60px;
  }
</style>

<script>
import axios from 'axios';
import conf from  "../../conf";
import VueGallery from 'vue-gallery';
import Banner from '../Banner/BannerLis.vue';

export default {
  name: "ProductsDetail",
  components: {
    "gallery" : VueGallery,
    "app-banner": Banner
  },
  props: {
    Name: String,
    Description: String,
    Spec: String,
    ImgURL: String
  },
  data() {
    return {
      ProductName: "",
      AllProduct: [],
      PRDContent: "",
      GalleryList: [],
      IndexGallery: null,
      ProductBanner: null
    };
  },
  methods: {
    LoadAllProduct() {
      axios.get(conf.ApiURL+`/Site/GetCategoryList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.AllProduct = res.data;
                this.getProductByName();
              });
    },
    getProductByName() {
      this.ProductList = [];
      this.AllProduct.forEach(item => {
        if (item.Name == this.ProductName) {
            // this.PRDContent = item.HtmlDesc;
            this.ProductBanner = item.Pic;
            this.getContent(item.Id);
            this.getGalleryList(item.Id);
            return false;
        }
      });
      // console.log(this.PRDContent);
    },
    getContent(_Id){
      axios.get(conf.ApiURL+`/Site/GetCategoryContent?Cid=`+_Id, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.PRDContent = res.data[0].HtmlDesc;
              });
    },
    getGalleryList(_Id){
      axios.get(conf.ApiURL+`/Site/GetCategoryGallery?Cid=`+_Id, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.GalleryList = [];
                let Data = res.data;
                for(var i = 0; i < Data.length; i++){
                  this.GalleryList.push(Data[i].Pic);
                }

                // console.log(this.GalleryList);
                // if(this.GalleryList.length > 0)
                //   this.IndexGallery = 0;
              });
    }
  },
  mounted() {
    this.ProductName = this.$route.params.name;
    this.LoadAllProduct();
  },
  beforeRouteLeave (to, from , next) {
    if(this.IndexGallery != null)
    {
      this.IndexGallery = null;
      next(false);
    }
    else{
      next();
    }
    
    // const answer = window.confirm('Do you really want to leave? you have unsaved changes!')
    // if (answer) {
    //   next()
    // } else {
    //   next(false)
    // }
  }
};
</script>

<style>
.img-gallery{
  width: 100%;
  height: auto;
}

.img-list{
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>
