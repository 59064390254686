<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <!-- <services-table title="Light Table"></services-table> -->
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="mb-0">{{title}}</h3>
                                </div>
                                <div class="col text-right">
                                    <base-button type="primary"  icon="fa fa-plus" v-on:click="showModalNew()">Create</base-button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                        <base-table class="table align-items-center table-flush" thead-classes="thead-light" tbody-classes="list" :data="tableData">

                            <template slot="columns">
                                <th>CMD</th>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Description</th>
                            </template>

                            <template slot-scope="{row}">
                                <td>
                                    <base-button type="info" icon="fa fa-edit" v-on:click="showModalEdit(row)" ></base-button>
                                    <base-button type="danger" icon="fa fa-trash" v-on:click="deleteData(row.Id)" ></base-button>
                                </td>
                                <td>
                                    <a href="#!" class="avatar" data-toggle="tooltip" data-original-title="">
                                        <img v-if="row.Pic !== 'null'" :src="ApiURL+'/uploads/categories/' + row.Pic">
                                    </a>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Name}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Description}}</span>
                                    </div>
                                </td>
                            </template>
                        </base-table>
                        </div>

                        <div class="card-footer d-flex justify-content-end">
                            <base-pagination :total="totalPage" ref="pageMenu"></base-pagination>
                        </div>

                        <!-- Modal -->
                        <modal :show.sync="modalEdit"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-lg">

                                <card shadow type="secondary">
                                    <div slot="header" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <h3 class="mb-0">Edit {{title}}</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="danger"  icon="ni ni-user-run" @click="modalEdit = false">Close</base-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                          <div class="col-lg-12">
                                            <tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="">
                                              <tab-pane id="home">
                                                <span slot="title">หน้าหลัก</span>
                                                <div class="row">
                                                  <div class="col-lg-4">
                                                      <label>Pic</label>
                                                      <div id="preview">
                                                      <img v-if="picurl !== null && picurl !== 'null'" :src="picurl" class="img-modal" />
                                                      </div>
                                                      <input type="file" ref="fileInput" @change="onFileChange" class="file_upload-modal" />
                                                      <!-- <file-input ref="fileInput" @change="onFileChange"></file-input> -->
                                                  </div>
                                                  <div class="col-lg-8">
                                                      <div class="form-group">
                                                          <input type="hidden" v-model="item.Id" />
                                                          <base-input label="ประเภทสินค้า" placeholder="Category Name" v-model="item.Name"></base-input>
                                                      </div>
                                                      <div class="form-group">
                                                          <base-input alternative=""
                                                                      label="รายละเอียด(ย่อย)">
                                                              <textarea rows="4" class="form-control form-control-alternative" placeholder="Description" v-model="item.Description"></textarea>
                                                          </base-input>
                                                      </div>
                                                  </div>                                                  
                                                </div>
                                              </tab-pane>

                                              <tab-pane id="prddesc">
                                                <span slot="title">ข้อมูลสินค้า</span>
                                                <div class="col-lg-12">
                                                  <label>รายละเอียดสินค้า</label>
                                                  <div class="bg-white contacthtml">
                                                    <vue-editor v-model="item.HtmlDesc" />
                                                  </div>
                                                </div>
                                              </tab-pane>

                                              <tab-pane id="gallery">
                                                <span slot="title">คลังภาพ</span>
                                                <div class="row">
                                                  <div class="col-sm-4 col-md-3 col-lg-3">
                                                    <card class="card">
                                                      <input type="file" ref="fileNewGallery" style="display: none" v-on:change="UploadImageGallery">
                                                      <div class="text-center" v-on:click="AddNewImageGallery">
                                                        <img class="card-img card-img-new" src="../assets/plus.png" alt="">
                                                        <div class="pt-2"> 
                                                          <h5>New File</h5>
                                                        </div>
                                                      </div>
                                                    </card>
                                                  </div>

                                                  <div class="col-sm-4 col-md-3 col-lg-3" v-for="(g,index) in listGallery" :key="index">
                                                    <card class="card">
                                                      <img class="card-img" :src="g.Pic" alt="">
                                                      <div class="pt-2"> 
                                                        <div class="float-right">
                                                          <base-button type="danger" v-on:click="DeleteImageGallery(g.Item)"><i class="fa fa-trash"></i></base-button>
                                                        </div>
                                                      </div>
                                                    </card>      
                                                  </div>
                                                </div>
                                              </tab-pane>
                                            </tabs>
                                          </div>
                                          
                                        </div>
                                    </div>
                                    </div>
                                    <div slot="footer" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <!-- <base-button type="info"  icon="ni ni-ruler-pencil">Save</base-button> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="info"  icon="ni ni-ruler-pencil" v-on:click="saveData()">Save</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                        </modal>
                        <!-- Modal -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import axios from 'axios';
  import conf from  "../conf";

  export default {
    name: 'category-page',
    props: {      
    //   title: String
    },
    data() {
      return {
        title: "Product Category",
        ApiURL: conf.ApiURL,
        minIndex: 1,
        maxIndex: 1,
        modalEdit: false,
        currentPage: 1,
        allData:[],
        tableData:[],
        totalPage:11,
        picurl: null,
        uploadFile: null,
        item: {
          Id: '',
          Name: '',
          Description: '',
          Pic: '',
          LastUpdate: null
        },
        listGallery:[]
      }
    },
    methods:{
      onFileChange(e) {
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.picurl = URL.createObjectURL(file);
      },
      resetFileInput() {
        const input = this.$refs.fileInput;
        input.type = 'text';
        input.type = 'file';
      },
      showModalNew(){
        this.resetFileInput();
        this.item = {Id:null, Name:null, Description:null, Pic:null, HtmlDesc: ""};
        this.picurl = null;
        this.modalEdit = true;
      },
      showModalEdit(_item){
        this.resetFileInput();
        this.item = _item;
        if(_item.Pic !== null && _item.Pic !== 'null')
          this.picurl = conf.ApiURL+"/uploads/categories/"+_item.Pic;
        else
          this.picurl = null;

        this.GetImageGallery();
        this.modalEdit = true;
      },
      getDataList(){
        axios.get(conf.ApiURL+`/Setting/GetCategories`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.allData = JSON.parse(res.data.Msg);
                  this.$refs.pageMenu.pageCount = 0;
                  this.$refs.pageMenu.setTotalRow(this.allData.length);
                  this.$refs.pageMenu.totalPages;
                  this.$refs.pageMenu.defaultPagesToDisplay = Math.ceil(this.allData.length / 10);
                  // console.log(this.$refs.pageMenu.defaultPagesToDisplay);
                  this.showDataList(this.currentPage);
                }
              });
      },
      showDataList(page){
        let maxLength = page * 10;
        let minLength = ((page * 10))-10;
        if(maxLength > this.allData.length-1)
          maxLength = this.allData.length;
        
        this.tableData = [];
        for(let i = minLength; i < maxLength; i++){
          this.tableData.push(this.allData[i]);
        }
      },
      saveData(){
        let formData;
        if(this.item.Id !== null && this.item.Id !== ''){
          formData = { Id: this.item.Id, Name: this.item.Name, Description: this.item.Description, HtmlDesc: this.item.HtmlDesc };

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.put(conf.ApiURL+`/Setting/UpdateCategory`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });

          if(this.uploadFile !== null){
            this.uploadImg(this.item.Id);
          }
        }
        else{
          formData = { Name: this.item.Name, Description: this.item.Description, Pic: null, HtmlDesc: this.item.HtmlDesc};

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.post(conf.ApiURL+`/Setting/CreateCategory`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    var _item = JSON.parse(msg);
                    // console.log(item);
                    if(this.uploadFile !== null){
                      this.uploadImg(_item.Key);
                    }

                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });
        }
      },
      deleteData(_Id){
        axios.delete(conf.ApiURL+`/Setting/DeleteCategory`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Id: _Id
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  this.modalEdit = false;
                  this.getDataList();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      },
      uploadImg(ItemKey){
        let formData = new FormData();
        formData.append("Id", ItemKey);
        formData.append("ImgFile", this.uploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadCategoryImage', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(res => {
                let status = res.data.Status;
                let msg = res.data.Msg;
                if(status != "SUCCESS")
                  this.showNotify(msg,"danger");                
              });
      },
      showNotify(_msg, _type = "success"){
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
      },
      AddNewImageGallery(){
        this.$refs.fileNewGallery.click();
      },
      GetImageGallery(){
        axios.get(conf.ApiURL+`/Setting/GetCategoryGallery?Cid=`+this.item.Id, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.listGallery = JSON.parse(res.data.Msg);
                  // console.log(this.listGallery);
                }
              });
      },
      UploadImageGallery(e)
      {
        if(this.item.Id == null || this.item.Id == '')
        {
          this.showNotify("กรุณาบันทึกข้อมูลก่อนอัพเดทรูปภาพ","danget");
          return;
        }

        let _UploadFile = e.target.files[0];

        if(_UploadFile == null)
          return;

        let formData = new FormData();
        formData.append("Cid", this.item.Id);
        formData.append("ImgFile", _UploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadCategoryGalleryImage', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(res => {
                let status = res.data.Status;
                let msg = res.data.Msg;
                if(status != "SUCCESS")
                  this.showNotify(msg,"danger");   
                  this.GetImageGallery();             
              });
        // this.picurl = URL.createObjectURL(file);
      },
      DeleteImageGallery(_Item){
        axios.delete(conf.ApiURL+`/Setting/DeleteCategoryGalleryImage`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Cid: this.item.Id,
                  Item: _Item
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  this.GetImageGallery();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      }
    },
    mounted(){
      this.getDataList();
    }
  }
</script>

<style>
.img-modal{
    width: 100%;
    height: auto;
}

.file_upload-modal{
    width: 100%;
    overflow: hidden;
}
</style>
