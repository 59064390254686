<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <!-- <services-table title="Light Table"></services-table> -->
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="mb-0">{{title}}</h3>
                                </div>
                                <div class="col text-right">
                                    <base-button type="primary"  icon="fa fa-plus" v-on:click="showModalNew()">Create</base-button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                        <base-table class="table align-items-center table-flush" thead-classes="thead-light" tbody-classes="list" :data="tableData">

                            <template slot="columns">
                                <th>CMD</th>
                                <th>Picture</th>
                                <th>Name</th>
                                <th>Link</th>
                            </template>

                            <template slot-scope="{row}">
                                <td>
                                    <base-button type="info" icon="fa fa-edit" v-on:click="showModalEdit(row)" ></base-button>
                                    <base-button type="danger" icon="fa fa-trash" v-on:click="deleteData(row.Id)" ></base-button>
                                </td>
                                <td>
                                    <a href="#!" class="avatar" data-toggle="tooltip" data-original-title="">
                                        <img v-if="row.Pic !== 'null'" :src="ApiURL+'/uploads/partners/' + row.Pic">
                                    </a>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Name}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Link}}</span>
                                    </div>
                                </td>
                            </template>
                        </base-table>
                        </div>

                        <div class="card-footer d-flex justify-content-end">
                            <base-pagination :total="totalPage" ref="pageMenu"></base-pagination>
                        </div>

                        <!-- Modal -->
                        <modal :show.sync="modalEdit"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-lg">

                                <card shadow type="secondary">
                                    <div slot="header" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <h3 class="mb-0">Edit {{title}}</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="danger"  icon="ni ni-user-run" @click="modalEdit = false">Close</base-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                        <div class="col-lg-4">
                                            <label>Pic</label>
                                            <div id="preview">
                                            <img v-if="picurl !== null && picurl !== 'null'" :src="picurl" class="img-modal" />
                                            </div>
                                            <input type="file" ref="fileInput" @change="onFileChange" class="file_upload-modal" />
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="form-group">
                                                <input type="hidden" v-model="item.Id" />
                                                <base-input label="Name" placeholder="Name" v-model="item.Name"></base-input>
                                            </div>
                                            <div class="form-group">
                                                <input type="hidden" v-model="item.Id" />
                                                <base-input label="Link" placeholder="Link" v-model="item.Link"></base-input>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div slot="footer" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <!-- <base-button type="info"  icon="ni ni-ruler-pencil">Save</base-button> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="info"  icon="ni ni-ruler-pencil" v-on:click="saveData()">Save</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                        </modal>
                        <!-- Modal -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import axios from 'axios';
  import conf from  "../conf";

  export default {
    name: 'partner-page',
    props: {      
    //   title: String
    },
    data() {
      return {
        title: "Partners",
        ApiURL: conf.ApiURL,
        minIndex: 1,
        maxIndex: 1,
        modalEdit: false,
        currentPage: 1,
        allData:[],
        tableData:[],
        totalPage:11,
        picurl: null,
        uploadFile: null,
        item: {
          Id: '',
          Name: '',
          Pic: null,
          Link: '',
          LastUpdate: null
        }
      }
    },
    methods:{
      onFileChange(e) {
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.picurl = URL.createObjectURL(file);
      },
      resetFileInput() {
        const input = this.$refs.fileInput;
        input.type = 'text';
        input.type = 'file';
      },
      showModalNew(){
        this.resetFileInput();
        this.item = {Id:null, Link:null, Pic:null};
        this.picurl = null;
        this.modalEdit = true;
      },
      showModalEdit(_item){
        this.resetFileInput();
        this.item = _item;
        if(_item.Pic !== null && _item.Pic !== 'null')
          this.picurl = conf.ApiURL+"/uploads/slides/"+_item.Pic;
        else
          this.picurl = null;

        this.modalEdit = true;
      },
      getDataList(){
        axios.get(conf.ApiURL+`/Setting/GetPartners`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.allData = JSON.parse(res.data.Msg);
                  this.$refs.pageMenu.pageCount = 0;
                  this.$refs.pageMenu.setTotalRow(this.allData.length);
                  this.$refs.pageMenu.totalPages;
                  this.$refs.pageMenu.defaultPagesToDisplay = Math.ceil(this.allData.length / 10);
                  this.showDataList(this.currentPage);
                }
              });
      },
      showDataList(page){
        let maxLength = page * 10;
        let minLength = ((page * 10))-10;
        if(maxLength > this.allData.length-1)
          maxLength = this.allData.length;
        
        this.tableData = [];
        for(let i = minLength; i < maxLength; i++){
          this.tableData.push(this.allData[i]);
        }
      },
      saveData(){
        let formData;
        if(this.item.Id !== null && this.item.Id !== ''){
          formData = { Id: this.item.Id, Name: this.item.Name, Link: this.item.Link };

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.put(conf.ApiURL+`/Setting/UpdatePartner`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });

          if(this.uploadFile !== null){
            this.uploadImg(this.item.Id);
          }
        }
        else{
          formData = { Name: this.item.Name, Link: this.item.Link, Pic: null};

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.post(conf.ApiURL+`/Setting/CreatePartner`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    var _item = JSON.parse(msg);
                    // console.log(item);
                    if(this.uploadFile !== null){
                      this.uploadImg(_item.Key);
                    }

                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });
        }
      },
      deleteData(_Id){
        axios.delete(conf.ApiURL+`/Setting/DeletePartner`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Id: _Id
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  this.modalEdit = false;
                  this.getDataList();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      },
      uploadImg(ItemKey){
        let formData = new FormData();
        formData.append("Id", ItemKey);
        formData.append("ImgFile", this.uploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadPartnerImage', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data'
                }
              })
              .then(res => {
                let status = res.data.Status;
                let msg = res.data.Msg;
                if(status != "SUCCESS")
                  this.showNotify(msg,"danger");                
              });
      },
      showNotify(_msg, _type = "success"){
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
      }
    },
    mounted(){
      this.getDataList();
    }
  }
</script>

<style>
.img-modal{
    width: 100%;
    height: auto;
}

.file_upload-modal{
    width: 100%;
    overflow: hidden;
}
</style>
