<template>
    <div>
        <base-header type="gradient-success" class="pb-4 pb-6 pt-5 pt-md-8">
        </base-header>

        <div class="container-fluid mt--7">
            <div class="row">
                <div class="col">
                    <!-- <services-table title="Light Table"></services-table> -->
                    <div class="card shadow">
                        <div class="card-header border-0">
                            <div class="row align-items-center">
                                <div class="col">
                                    <h3 class="mb-0">{{title}}</h3>
                                </div>
                                <div class="col text-right">
                                    <base-button type="primary"  icon="fa fa-plus" v-on:click="showModalNew()">Create</base-button>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive">
                        <base-table class="table align-items-center table-flush" thead-classes="thead-light" tbody-classes="list" :data="tableData">

                            <template slot="columns">
                                <th>CMD</th>
                                <th>Picture</th>
                                <th>Category</th>
                                <th>Name</th>
                                <th>Description</th>                                
                            </template>

                            <template slot-scope="{row}">
                                <td>
                                    <base-button type="info" icon="fa fa-edit" v-on:click="showModalEdit(row)" ></base-button>
                                    <base-button type="danger" icon="fa fa-trash" v-on:click="deleteData(row.Pid)" ></base-button>
                                </td>
                                <td>
                                    <a href="#!" class="avatar" data-toggle="tooltip" data-original-title="">
                                        <img v-if="row.Pic !== 'null'" :src="ApiURL+'/uploads/products/' + row.Pic">
                                    </a>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Categoryid}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Name}}</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="media-body">
                                        <span class="name mb-0 text-sm">{{row.Description}}</span>
                                    </div>
                                </td>
                            </template>
                        </base-table>
                        </div>

                        <div class="card-footer d-flex justify-content-end">
                            <base-pagination :total="totalPage" ref="pageMenu"></base-pagination>
                        </div>

                        <!-- Modal -->
                        <modal :show.sync="modalEdit"
                                body-classes="p-0"
                                modal-classes="modal-dialog-centered modal-lg">

                                <card shadow type="secondary">
                                    <div slot="header" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <h3 class="mb-0">Edit {{title}}</h3>
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="danger"  icon="ni ni-user-run" @click="modalEdit = false">Close</base-button>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                        <div class="col-lg-4">
                                            <label>Pic</label>
                                            <div id="preview">
                                            <img v-if="picurl !== null && picurl !== 'null'" :src="picurl" class="img-modal" />
                                            </div>
                                            <input type="file" ref="fileInput" @change="onFileChange" class="file_upload-modal" />
                                            <!-- <file-input ref="fileInput" @change="onFileChange"></file-input> -->
                                        </div>
                                        <div class="col-lg-8">
                                            <div class="form-group">
                                                <input type="hidden" v-model="item.Pid" />
                                                <!-- <base-input label="ประเภทสินค้า" placeholder="Product Category" v-model="item.Categoryid"></base-input> -->
                                                <base-input label="ประเภทสินค้า">
                                                  <select class="form-control" v-model="item.Categoryid">
                                                    <option value="">--กรุณาเลือก--</option>
                                                    <option v-for="(cate, index) in CategoryList" :key="index" v-bind:value="cate.Id" >{{cate.Name}}</option>
                                                    <!-- <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option> -->
                                                  </select>
                                                </base-input>
                                            </div>
                                            <div class="form-group">
                                                <base-input label="ชื่อสินค้า" placeholder="Product Name" v-model="item.Name"></base-input>
                                            </div>
                                            <div class="form-group">
                                                <base-input alternative=""
                                                            label="รายละเอียด">
                                                    <textarea rows="4" class="form-control form-control-alternative" placeholder="Description" v-model="item.Description"></textarea>
                                                </base-input>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div slot="footer" class="bg-white border-0">
                                        <div class="row align-items-center">
                                            <div class="col-8">
                                                <!-- <base-button type="info"  icon="ni ni-ruler-pencil">Save</base-button> -->
                                            </div>
                                            <div class="col-4 text-right">
                                                <base-button type="info"  icon="ni ni-ruler-pencil" v-on:click="saveData()">Save</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </card>
                        </modal>
                        <!-- Modal -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
  import axios from 'axios';
  import conf from  "../conf";

  export default {
    name: 'product-page',
    props: {      
    //   title: String
    },
    data() {
      return {
        title: "Product",
        minIndex: 1,
        maxIndex: 1,
        modalEdit: false,
        currentPage: 1,
        allData:[],
        tableData:[],
        totalPage:11,
        picurl: null,
        uploadFile: null,
        ApiURL: '',
        CategoryList: [],
        item: {
          Pid: null,
          Name: null,
          Description: null,
          Categoryid: null,
          Pic: '',
          Spec: null,
          Spec2: null,
          Spec3: null,
          LastUpdate: null
        }
      }
    },
    methods:{
      onFileChange(e) {
        const file = e.target.files[0];
        this.uploadFile = e.target.files[0];
        this.picurl = URL.createObjectURL(file);
      },
      resetFileInput() {
        const input = this.$refs.fileInput;
        input.type = 'text';
        input.type = 'file';
      },
      showModalNew(){
        this.resetFileInput();
        this.item = {Pid:null, Name:null, Description:null, Pic:null, Categoryid:null, spec:null, spec2:null, spec3:null};
        this.picurl = null;
        this.modalEdit = true;
      },
      showModalEdit(_item){
        this.resetFileInput();
        this.item = _item;
        if(_item.Pic !== null && _item.Pic !== 'null')
          this.picurl = conf.ApiURL+"/uploads/products/"+_item.Pic;
        else
          this.picurl = null;

        this.modalEdit = true;
      },
      getDataList(){
        axios.get(conf.ApiURL+`/Setting/GetProducts`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.allData = JSON.parse(res.data.Msg);
                  this.$refs.pageMenu.pageCount = 0;
                  this.$refs.pageMenu.setTotalRow(this.allData.length);
                  this.$refs.pageMenu.totalPages;
                  this.$refs.pageMenu.defaultPagesToDisplay = Math.ceil(this.allData.length / 10);
                  // console.log(this.$refs.pageMenu.defaultPagesToDisplay);
                  this.showDataList(this.currentPage);
                }
              });
      },
      showDataList(page){
        let maxLength = page * 10;
        let minLength = ((page * 10))-10;
        if(maxLength > this.allData.length-1)
          maxLength = this.allData.length;
        
        this.tableData = [];
        for(let i = minLength; i < maxLength; i++){
          this.tableData.push(this.allData[i]);
        }
      },
      saveData(){
        let formData;
        // console.log(this.item);
        if(this.item.Pid !== null && this.item.Pid !== ''){
          formData = { Pid: this.item.Pid, Name: this.item.Name, Description: this.item.Description, 
                        Categoryid: parseInt(this.item.Categoryid), Spec: this.item.spec, Spec2: this.item.Spec2, Spec3: this.item.Spec3 };

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.put(conf.ApiURL+`/Setting/UpdateProduct`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });

          if(this.uploadFile !== null){
            this.uploadImg(this.item.Pid);
          }
        }
        else{
          formData = { Name: this.item.Name, Description: this.item.Description, Categoryid: this.item.Categoryid, Pic: null};

          const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
          };

          axios.post(conf.ApiURL+`/Setting/CreateProduct`, 
                  encodeForm(formData)
                )
                .then(res => {
                  var status = res.data.Status;
                  var msg = res.data.Msg;
                  if(status === "SUCCESS"){
                    var _item = JSON.parse(msg);
                    // console.log(item);
                    if(this.uploadFile !== null){
                      this.uploadImg(_item.Key);
                    }

                    this.modalEdit = false;
                    this.getDataList();
                    this.showNotify("บันทึกข้อมูลเรียบร้อยแล้ว");
                  }
                  else{
                    this.showNotify(msg,"danger");
                  }
                });
        }
      },
      deleteData(_Id){
        axios.delete(conf.ApiURL+`/Setting/DeleteProducts`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                },
                params:{
                  Pid: _Id
                }
              })
              .then(res => {
                var status = res.data.Status;
                if(status === "SUCCESS"){
                  this.modalEdit = false;
                  this.getDataList();
                  this.showNotify("ลบข้อมูลเรียบร้อยแล้ว");
                }
              });
      },
      showNotify(_msg, _type = "success"){
        this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
      },
      uploadImg(ItemKey){
        let formData = new FormData();
        formData.append("Pid", ItemKey);
        formData.append("ImgFile", this.uploadFile);
        axios.post(conf.ApiURL+'/Setting/UploadProductsImage', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
            })
            .then(res => {
              let status = res.data.Status;
              if(status != "SUCCESS")
                this.showNotify(res.data.Msg,"danger");
            });
      },
      getCategoryList(){
        axios.get(conf.ApiURL+`/Setting/GetCategories`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                var Status = res.data.Status;
                if(Status == "SUCCESS")
                {
                  this.CategoryList = JSON.parse(res.data.Msg);
                }
              });
      }
    },
    mounted(){
      this.ApiURL = conf.ApiURL;
      this.getCategoryList();
      this.getDataList();
    }
  }
</script>

<style>
.img-modal{
    width: 100%;
    height: auto;
}

.file_upload-modal{
    width: 100%;
    overflow: hidden;
}
</style>
