<template>
  <div>
    <img v-if="ServiceBanner != null" :src="ServiceBanner" class="main-banner-img" />
    <section class="service-area section-gap" id="service">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 pb-30 header-text text-center">
            <h1 class="mb-10">บริการของเรา</h1>
            <!-- <p>Our Service</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4" v-for="(service, index) in SerciveList" :key="index">
            <div class="single-service">
              <div class="thumb">
                <img v-if="service.Pic !== null" :src="service.Pic"/>
                <img v-else src="./../../assets/img/image_not_available.png" />
              </div>
              <h4>{{service.Name}}</h4>
              <p class="desc" v-if="service.Description !== null && service.Description !== 'null'">{{service.Description}}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';
import conf from  "../../conf";

export default {
  name: "Services",
  components: {},
  data() {
    return {
      SerciveList: [],
      StaticData: [],
      ServiceBanner: null
    };
  },
  methods: {
    getServices() {
      axios.get(conf.ApiURL+`/Site/GetServiceList`, {
                headers: {
                  "Access-Control-Allow-Origin": "*"
                }
              })
              .then(res => {
                this.SerciveList = res.data;
              });
      // var Service;
      // Service = {
      //   Name: "หล่อลูกกลิ้งยางอุตสาหกรรม",
      //   Pic: "หล่อลูกกลิ้ง.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
      // Service = { Name: "สร้างแกน", Pic: "งานสร้างแกน.jpg", Description: "" };
      // this.SerciveList.push(Service);
      // Service = {
      //   Name: "ซ่อม - เปลี่ยน",
      //   Pic: "รับซ่อม_เปลี่ยน.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
      // Service = { Name: "เจียรผิว", Pic: "เจียรผิว.jpg", Description: null };
      // this.SerciveList.push(Service);
      // Service = {
      //   Name: "รับตัดร่อง ขึ้นลายบนผิวยาง",
      //   Pic: "ตัดร่อง_ขึ้นลาย ผิวยาง.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
      // Service = {
      //   Name: "ตัดร่อง ขึ้นลาย กลึงร้อง กัดลาย งานเล็ก สแตนเลส",
      //   Pic: "ตัดร่อง_ขึ้นลาย ผิวยาง_2.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
      // Service = {
      //   Name: "ถ่วงสมดุล ลูกกลิ้ง",
      //   Pic: "ถ่วงสมดุล.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
      // Service = {
      //   Name: "ชุปฮาร์โครม เคลือบเทฟล่อน",
      //   Pic: "ชุบฮาร์ดโครม.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
      // Service = {
      //   Name: "ผลิตชิ้นส่วนอะไหล่ และอุปกรณ์",
      //   Pic: "ผลิตชิ้นส่วนอะไหล่.jpg",
      //   Description: null
      // };
      // this.SerciveList.push(Service);
    },
    getSiteData(){
      setTimeout(() => {
        this.StaticData = this.$parent.getStaticData();
        if(this.StaticData.length == 1)
        {
          this.ServiceBanner = this.StaticData[0].ServiceBanner;
        }
        else{
          this.getSiteData();
        }
      }, 500);
      // this.StaticData = this.$parent.getStaticData();
      // if(this.StaticData.length == 1)
      // {
      //   this.ServiceBanner = this.StaticData[0].ServiceBanner;
      // }
    }
  },
  mounted() {
    this.getSiteData();
    this.getServices();
  }
};
</script>

<style>
.desc {
  white-space: pre-wrap; 
  word-wrap: break-word;
  font-family: inherit;
}
</style>
