<template>
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7">
                <div class="card bg-secondary shadow border-0">
                    <!-- <div class="card-header bg-transparent pb-5">
                        <div class="text-muted text-center mt-2 mb-3"><small>Sign in with</small></div>
                        <div class="btn-wrapper text-center">
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/github.svg"></span>
                                <span class="btn-inner--text">Github</span>
                            </a>
                            <a href="#" class="btn btn-neutral btn-icon">
                                <span class="btn-inner--icon"><img src="img/icons/common/google.svg"></span>
                                <span class="btn-inner--text">Google</span>
                            </a>
                        </div>
                    </div> -->
                    <div class="card-body px-lg-5 py-lg-5">
                        <div class="text-center text-muted mb-4">
                            <h2>Sign in</h2>
                        </div>
                        <!-- <div class="text-center text-muted mb-4">
                            <small>Or sign in with credentials</small>
                        </div> -->
                        <form role="form">
                            <base-input class="input-group-alternative mb-3"
                                        placeholder="Username"
                                        addon-left-icon="ni ni-circle-08"
                                        v-model="model.username">
                            </base-input>

                            <base-input class="input-group-alternative"
                                        placeholder="Password"
                                        type="password"
                                        addon-left-icon="ni ni-lock-circle-open"
                                        v-model="model.password">
                            </base-input>

                            <base-checkbox class="custom-control-alternative">
                                <span class="text-muted">Remember me</span>
                            </base-checkbox>
                            <div class="text-center">
                                <base-button type="primary" class="my-4" @click="DoLogin">Sign in</base-button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <a href="#" class="text-light"><small>Forgot password?</small></a>
                    </div>
                    <!-- <div class="col-6 text-right">
                        <router-link to="/register" class="text-light"><small>Create new account</small></router-link>
                    </div> -->
                </div>
            </div>
        </div>
</template>
<script>
  import axios from 'axios';
  import conf from  "../conf";

  export default {
    name: 'login',
    data() {
      return {
        model: {
          username: '',
          password: ''
        }
      }
    },
    methods:{
        DoLogin(){

            let formData = { 
                Username: this.model.username,
                Password: this.model.password
            };

            const encodeForm = (data) => {
            return Object.keys(data)
                .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
                .join('&');
            };

            axios.post(conf.ApiURL+`/Setting/AdminLogin`, encodeForm(formData))
                .then(res => {
                    var status = res.data.Status;
                    var msg = res.data.Msg;
                    if(status === "SUCCESS"){
                        var item = JSON.parse(msg);
                        this.$session.set('Token', item.Token);
                        this.$router.push('/admin/static');
                    }
                    else{
                        this.showNotify(msg,"danger");
                    }
                });
        },
        showNotify(_msg, _type = "success"){
            this.$notify({verticalAlign: 'top', horizontalAlign: 'right', icon:'ni ni-check-bold', message: _msg, type: _type});
        }
    }
  }
</script>
<style>
</style>
